import { toast } from 'react-toastify';

type ShareOptions = {
  title?: string;
  text?: string;
};

export const useShare = () => {
  const handleShare = ({ title = 'Share this page', text = 'TV-Media' }: ShareOptions) => {
    const url = window.location.href;
    if (navigator?.share) {
      navigator
        .share({
          title,
          text,
          url,
        })
        .catch((error) => {
          console.error('Error sharing:', error);
        });
    } else {
      // Alternatively, copy to clipboard
      const clipboardItemData = {
        ['text/plain']: `${title}\n${url}\n${text}`,
      };
      const clipboardItem = new ClipboardItem(clipboardItemData);
      navigator.clipboard.write([clipboardItem]).then(() => {
        toast.success('Link wurde kopiert.');
      });
    }
  };

  return [handleShare];
};
