import React, { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import { Image, AditionTagWithFallback, Link, Button } from '@vgn-medien-holding/vgn-fe-components';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { de } from 'date-fns/locale/de';
import { twMerge } from 'tailwind-merge';
import { EntityInfo } from '@components/atoms/EntityInfo/EntityInfo';
import { FallbackCard } from '@components/atoms/FallbackCard/FallbackCard';
import { Genre } from '@components/atoms/Genre/Genre';
import { LoadingIndicator } from '@components/atoms/LoadingIndicator/LoadingIndicator';
import { Progress } from '@components/atoms/Progress/Progress';
import { createDate, isValidDateString } from '@utils/dateHelpers';
import { IconPlayTvguide } from '@src/assets/icon-play-tvguide';
import { IconShare } from '@src/assets/icon-share';
import { IconShareAlternative } from '@src/assets/icon-share-alternative';
import { useShare } from '@src/lib/hooks/useShare';
import { useTvGuideStore } from '@src/stores/tvguide';
import { getChannelLogo } from '@src/utils/sortedChannels';
import { adPositions } from '@lib/adition/adPositions';
import { TvChannel, TvChannelShowtime, GetChannelShowtimeByEventIdDocument } from '@lib/graphql/generated';
import { useQuery } from '@lib/graphql/urql';
import { IconClose } from '@assets/icon-close';
import { FavoriteButton } from '../FavoriteButton/FavoriteButton';

export interface ShowtimeDetailsProps {
  entryEventId: string;
  channel: TvChannel;
  onFetchComplete?: (tvChannelShowtime, tvChannel) => void;
}

export const ShowtimeDetails = ({ entryEventId, channel, onFetchComplete }: ShowtimeDetailsProps) => {
  const setOpenEntryData = useTvGuideStore((state) => state.setOpenEntryData);
  const [currentTime, setCurrentTime] = useState(toZonedTime(new Date(), 'Europe/Vienna').getTime());
  const pathname = usePathname();
  const [handleShare] = useShare();

  const [{ data, fetching: loading }] = useQuery({
    query: GetChannelShowtimeByEventIdDocument,
    variables: { id: entryEventId, includeChannel: true },
    pause: !entryEventId,
  });
  const channelShowtimeByEventId = data?.channelShowtimeByEventId;

  useEffect(() => {
    if (!loading && channelShowtimeByEventId) {
      onFetchComplete(channelShowtimeByEventId, channel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, channelShowtimeByEventId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(toZonedTime(new Date(), 'Europe/Vienna').getTime());
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  let progress = 0;

  if (channelShowtimeByEventId) {
    const startTime = createDate(channelShowtimeByEventId?.start).getTime();
    const endTime = createDate(channelShowtimeByEventId?.stop).getTime();

    if (currentTime > endTime || currentTime < startTime) {
      progress = -1;
    } else {
      progress = ((currentTime - startTime) / (endTime - startTime)) * 100;
    }
  }

  return (
    <div
      className={twMerge(
        'pointer-events-none fixed inset-0 z-80 overflow-y-auto overflow-x-clip overscroll-contain border-l border-gray-650/25 bg-black opacity-0 transition-opacity duration-300 scrollbar-none sm:inset-y-0 sm:left-auto sm:right-0 sm:w-96',
        entryEventId && 'pointer-events-auto opacity-100',
      )}
      onClick={(e) => e.stopPropagation()}
    >
      {channelShowtimeByEventId ? (
        <>
          <div className="relative aspect-video w-full">
            <div className="size-full overflow-hidden">
              {channelShowtimeByEventId?.image?.url ? (
                <Image
                  src={channelShowtimeByEventId.image.url}
                  alt={channelShowtimeByEventId.title}
                  fill
                  copyright=""
                  sizes="400px"
                />
              ) : (
                <FallbackCard />
              )}
              <div className="fixed right-6 top-6 z-10">
                <div
                  className="grid size-8 cursor-pointer place-items-center rounded-full border-gray-650/50 bg-black/80"
                  onClick={() => {
                    setOpenEntryData();
                  }}
                >
                  <IconClose classProps={{ root: 'w-3.5 h-3.5' }} />
                </div>
              </div>
            </div>
            <Progress
              percentage={progress}
              classProps={{
                root: 'h-2',
                innerCircle: 'size-4 -right-1.5 -top-1',
                outerCircle: 'size-6 -right-2.5 -top-2',
              }}
            />
          </div>
          <div className="p-8">
            <div className="mb-4">
              {channel && (
                <Image
                  src={getChannelLogo(channel.id)}
                  alt={channel.name}
                  width={40}
                  height={6}
                  copyright=""
                  classProps={{ container: 'h-3.5 w-full', root: 'w-auto' }}
                />
              )}
              {isValidDateString(channelShowtimeByEventId?.start) && (
                <div className="mt-2 flex h-4 items-center gap-1 leading-body">
                  <span className="inline-block">
                    {format(new Date(channelShowtimeByEventId?.start), 'EEE dd.M.yy', { locale: de })}
                  </span>
                  <span className="inline-block size-[3px] rounded-full bg-white"></span>
                  <span className="inline-block">
                    {format(new Date(channelShowtimeByEventId?.start), 'HH:mm', { locale: de })} Uhr{' '}
                    <span className="ml-1 mr-2">-</span>
                    {format(new Date(channelShowtimeByEventId?.stop), 'HH:mm', { locale: de })} Uhr
                  </span>
                </div>
              )}
            </div>
            <div className="font-herokid text-heading-6 text-white">{channelShowtimeByEventId?.title}</div>
            {channelShowtimeByEventId?.subtitle != channelShowtimeByEventId?.title && (
              <div className="heading-7 mt-1 text-white">{channelShowtimeByEventId?.subtitle}</div>
            )}
            <div className="flex min-w-0 flex-wrap justify-start gap-1 py-5 empty:hidden md:gap-2">
              {channelShowtimeByEventId.genre
                ?.split(' / ')
                ?.map((genre, index) => <Genre key={index} genre={{ title: genre, id: '' + index }} />)}
            </div>
            <div className="mt-2 flex items-center gap-2">
              <FavoriteButton
                id={channelShowtimeByEventId.event_id}
                type="TVChannelShowtime"
                loginFlowTitle="Speichere jetzt Deine Lieblingsserien und -filme"
                classProps={{ iconWrapper: 'size-10 bg-gray-820/70 border-gray-650/25', icon: 'size-5' }}
              />
              <Link
                href={pathname}
                target="_blank"
                classProps={{
                  root: 'flex size-10 flex-none place-items-center justify-center gap-2 rounded-full border border-gray-650/25 bg-gray-820/70 transition-colors duration-200 hover:border-primary hover:text-primary',
                }}
              >
                <IconShareAlternative />
              </Link>
              <Button
                hasAction
                classProps={{
                  root: 'flex size-10 flex-none place-items-center justify-center gap-2 rounded-full border border-gray-650/25 bg-gray-820/70 transition-colors duration-200 hover:border-primary hover:text-primary',
                }}
                onAction={() => {
                  handleShare({
                    title:
                      'Das musst du dir unbedingt anschauen:\n' +
                      channelShowtimeByEventId.title +
                      (channelShowtimeByEventId.year && ` (${channelShowtimeByEventId.year})`),
                    text:
                      format(new Date(channelShowtimeByEventId?.start), 'EEE dd.M.yy', { locale: de }) +
                      ' (' +
                      format(new Date(channelShowtimeByEventId?.start), 'HH:mm', { locale: de }) +
                      ' - ' +
                      format(new Date(channelShowtimeByEventId?.stop), 'HH:mm', { locale: de }) +
                      'Uhr)',
                  });
                }}
              >
                <IconShare />
              </Button>
            </div>
            <div
              className="mt-6 max-w-full overflow-x-hidden text-gray-400"
              dangerouslySetInnerHTML={{ __html: channelShowtimeByEventId?.summary }}
            ></div>
            {progress > 0 && !!channelShowtimeByEventId?.channel?.joyn_livestream && (
              <a
                id="tv-sendung-anschauen-button"
                href={channelShowtimeByEventId?.channel?.joyn_livestream}
                onClick={() =>
                  window.fathom.trackEvent('Anschauen - ' + channelShowtimeByEventId?.channel?.joyn_livestream)
                }
                target="_blank"
                className={
                  'mt-6 flex min-h-[43px] cursor-pointer place-content-center items-center gap-2 rounded-full border border-primary bg-transparent px-8 py-2.5 font-herokid text-2xs font-bold uppercase leading-body tracking-widest text-white shadow-card-sm transition-colors duration-300 hover:bg-primary'
                }
              >
                <IconPlayTvguide />
                Anschauen
              </a>
            )}
          </div>
          <div className="mx-8 grid place-items-center">
            <AditionTagWithFallback
              tag={adPositions[32]}
              fallback={adPositions[24]}
              breakpoint={'sm'}
              classProps={{ root: 'pb-4' }}
            />
          </div>
          <ShowtimeInfoBar showtime={channelShowtimeByEventId} />
          <div className="space-y-8 p-8">
            {channelShowtimeByEventId?.directors_summary && (
              <ShowtimeInfoTextSection title="Regie" text={channelShowtimeByEventId?.directors_summary} />
            )}
            {channelShowtimeByEventId?.actors_summary && (
              <ShowtimeInfoTextSection title="Darsteller" text={channelShowtimeByEventId?.actors_summary} />
            )}
          </div>
        </>
      ) : loading ? (
        <LoadingIndicator visible classProps={{ root: 'animate-pulsesoft w-full h-full' }} />
      ) : (
        <></>
      )}
    </div>
  );
};

const ShowtimeInfoBar = ({ showtime }: { showtime: TvChannelShowtime }) => {
  if (!showtime) return null;
  return (
    <div className="mt-8">
      <aside className="grid w-full items-center divide-y divide-gray-650/25 border-y border-gray-650/25 bg-gray-820/50 px-8">
        <div className="flex min-w-0 justify-center gap-6 py-5 empty:hidden">
          {!!showtime.year && <EntityInfo label="Erscheinungsjahr" value={showtime.year} />}
          {!!showtime.countries.split(',')[0] && <EntityInfo label="Land" value={showtime.countries.split(',')[0]} />}
        </div>
      </aside>
    </div>
  );
};

interface ShowtimeInfoTextSectionProps {
  title: string;
  text: string;
}

const ShowtimeInfoTextSection = ({ title, text }: ShowtimeInfoTextSectionProps) => {
  return (
    <div className="space-y-2">
      <p className="label-sm">{title}</p>
      <p className="text-gray-400">{text.split(',').join(', ')}</p>
    </div>
  );
};
